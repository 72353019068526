import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Section } from '../components/common/section';
import ContentHero from '../components/content-hero';
import ContentWyswyg from '../components/content-wyswyg';

import PostAuthorAvatar from '../images/blog-article-author-avatar.png';

import articleContentPicture from '../images/article-content-image.png';

const Article = () => (
  <Layout
    socialDark={true}
    currentPage='content-sample'
  >
    <SEO title="Content Sample" />

    <Section>
      <ContentHero
        visual="blog-article-hero.png"
        visualAlt="Hello world"
        title="Hey! It’s been a long time, buddy!"
        postAuthorAvatar={PostAuthorAvatar}
        postAuthor="Lionel Cordier"
        postDate="June 27"
        text="<p>What have we been up to these last months? What’s the future of Ludus? It’s time for an update.</p>"
      />
    </Section>

    <Section>
      <ContentWyswyg
        content={[
          {
            type: 'text',
            html: `
              <p>Let’s speak frankly, we know that during the past few months we have been much more silent than we used to be. Nothing terrible happened to us, it’s just that we have been working hard and we have been learning from our mistakes. Almost two years ago we released the live collaboration feature to let people work together on the same presentation, with a simple</p>
            `
          },
          {
            type: 'text',
            html: `
              <h2>A new viewer</h2>
              <p>The viewer is a crucial part of a presentation tool. We changed it many times: we added social features such as comments, likes, views, etc. Some users were unhappy with these social features, so we added a way to hide them, then we also proposed a minimal mode to get back to an experience similar to the very first Ludus viewer. But at the same time, new great features were added such as Speaker View and Ludus Vox. It started to feel crowded and quite complex, with too many settings.</p>
              <p>We now want to propose a viewer that truly sticks to our vision. A beautiful and extremely simple one, yet with all the necessary features.</p>
              <p>We are so excited about it we want to show it already now that we are polishing it.</p>
            `
          },
          {
            type: 'picture',
            src: articleContentPicture,
            alt: 'Alt image'
          },
          {
            type: 'text',
            html: `
              <h3>Advice</h3>
              <p class="note">
                Be careful, fonts are authors' property and they are either freeware, shareware, demo versions or public domain. Always check the readme-files in the archives or visit the author's website for details. Contact him/her if you have any doubt.
              </p>
            `
          },
          {
            type: 'quote',
            quote: `We now want to propose a viewer that truly sticks to our vision. A beautiful and extremely simple one, yet with all the necessary features.`,
            author: {
              name: 'Rick Sanchez',
              title: 'CEO of the world'
            }
          },
          {
            type: 'picture',
            src: articleContentPicture,
            alt: 'Alt image'
          },
          {
            type: 'text',
            html: `
              <h3>Advice</h3>
              <p class="note">
                Be careful, fonts are authors' property and they are either freeware, shareware, demo versions or public domain. Always check the readme-files in the archives or visit the author's website for details. Contact him/her if you have any doubt.
              </p>
            `
          },
          {
            type: 'tags',
            tags: [
              {
                label: 'Design',
                url: ''
              },
              {
                label: 'Marketing',
                url: ''
              },
              {
                label: 'Presentation',
                url: ''
              },
            ]
          },
          {
            type: 'post-navigation',
            prev: {
              url: '#prev-post',
              title: "New stuff you might have missed"
            },
            next: {
              url: '#next-post',
              title: "New stuff you might have missed"
            }
          },
        ]}
      />
    </Section>

    
  </Layout>
)

export default Article;
